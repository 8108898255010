import React from 'react';

// const basePath = "https://young-tor-08987.herokuapp.com/";
// export const imgPath = 'http://sms-api.fusionelleconsult.com/static/';
const basePath = "https://test-api.taussh.com/";
export const imgPath = "https://test-api.taussh.com/static/";
export const base = basePath;
export const baseUrl = base;
export const imageUrl = basePath + 'static/';
export const currency = '\u20A6';
export const jwtToken = "dc6675e1cf3d3ce4f8ad17e7c518e5ca2832ed4c";
export const errorMsg = 'Sorry unable to process your request at this time';

export const urls = {
  login: 'login/',
  schemaList: 'schemaList/',
  users: 'users/',
  students: 'students/',
  parents: 'parents/',
  groups: 'groups/',
  users: 'users/',
  campus: 'campus/',
  token: 'api/jwt/token/',
  tokenRefresh: 'api/jwt/token/refresh/',
  campus: 'campus/',
  grades: 'grades/',
  classrooms: 'classrooms/',
  subjects: 'subjects/',
  examstype: 'examtype/',
  exams: 'exam/',
  homework: 'homework/',
  discipline: 'discipline/',
  attendance: 'attendance/'
}

export function formData(columns) {
  var formData = [];
  for (var f in columns) {
    var input = <input type={columns[f].type} className="form-control" name={columns[f].name} />;
    if (columns[f].type == "select") {
      var optionList = [];
      for (var d in columns[f].data) {
        optionList.push(<option class="dropdown-item" value={columns[f].data[d].value}>{columns[f].data[d].label}</option>);
      }
      input = <select name={columns[f].name} tabindex="-1" class="select2 form-control" id="default-select"
        value={this.state.payload[f]} onChange={(e) => this.onChange(e)}>
        <option class="dropdown-item">Select {columns[f].label}</option>
        {optionList}
      </select>
    }
    formData.push(<div class="col-lg-6 form-group" style={{ marginBottom: 10 }}>
      <label for="normal-field" class="form-control-label text-md-left">{columns[f].label}</label>
      {input}
    </div>);
  }
  return formData;
}
export const messages = {
  defaultError: {
    code: 5000,
    message: 'Sorry we are not able to process this at this time, kindly retry',
  },
  loginError: {
    error: true,
    message: 'Sorry we are not able to process this at this time, kindly retry',
  },
};

export function formatDate(date) {
  if (date !== null) return date.substring(0, 25);
  else return '';
}

/**
 Get the baseUrl from the server
 */
export function getUserData() {
  let userData = localStorage.getItem('userD');
  let user = JSON.parse(userData);
  return user;
}

export function formatErrors(data) {
  var errors = [];
  if (typeof (data) !== 'object') {
    errors.push(<div>{data}</div>);
  } else {
    for (var e in data) {
      if (data[e][0] == "This field is required.")
        errors.push(<div>{e.replace(/^\w/, (c) => c.toUpperCase())} is a required field</div>);
      else
        errors.push(<div>{data[e][0]}</div>);
    }
  }
  return errors;
}

export function formatErrorsMsg(data) {
  var errors = [];
  for (var e in data) {
    if (data[e][0] == "This field is required.")
      errors.push(`${e.replace(/^\w/, (c) => c.toUpperCase())} is a required field`);
    else
      errors.push(data[e][0]);
  }
  return errors;
}

export function generateDropdownData(listData, columns, image = null) {
  var list = [];
  if (listData.code != undefined) {
    if (listData.code == 200) {
      var cols = columns.split("|");
      for (var d in listData.message.results) {
        const data = listData.message.results[d];
        var label = data[cols[1]];
        if (cols.length > 2) {
          label = "";
          for (var l in cols) {
            if (l > 0)
              label += data[cols[l]] + " - ";
          }
        }
        var content = { label: label, value: data[cols[0]] };
        if (image != null) {
          content.image = data[image];
        }
        list.push(content);
      }
    }
  }
  return list;
}

export function generateDropdownDataPlain(listData, columns, image = null) {
  var list = [];
    var cols = columns.split("|");
    for (var d in listData) {
      const data = listData[d];
      var label = data[cols[1]];
      if (cols.length > 2) {
        label = "";
        for (var l in cols) {
          if (l > 0)
            label += data[cols[l]] + " - ";
        }
      }
      var content = { label: label, value: data[cols[0]] };
      if (image != null) {
        content.image = data[image];
      }
      list.push(content);
    }
  return list;
}


export function formateLabel(label) {
  if (label.endsWith("_id"))
    label = label.slice(0, -3);

  label = label.split("_").join(" ");
  label = label.replace(/^\w/, (c) => c.toUpperCase());
  console.log(label);
  return label;
}

export function isLoggedIn() {
  let userData = localStorage.getItem('userD');
  if (userData !== null) {
    let user = JSON.parse(userData);
    if (user.id !== undefined) {
      return true;
    }
  }
  return false;
}


/**
 Check if the string is a json data
 */
export function isJSONValid(data) {
  try {
    let json = JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 Patch message
 @params url, data
 */
export async function PatchData(url, data, sid, formtype = null) {
  let user = await getUserData();
  try {

    var token = await localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    if (formtype == null)
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      body: data,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    console.log(error);
    return messages.defaultError;
  }
}


/**
Post message
@params url, data
*/
export async function PostData(url, data, sid, formtype = null) {
  let user = await getUserData();
  try {
    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    if (formtype == null)
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    myHeaders.append("Authorization", "Bearer " + token);
    var requestOptions = {
      method: 'POST',
      body: data,
      headers: myHeaders,
      redirect: 'follow'
    };

    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    console.log(error);
    return messages.defaultError;
  }
}

/**
 Put Requests
 **/

export async function PutData(url, data) {
  try {
    let user = await getUserData();
    var headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    headers['access-token'] = user.access_token;
    headers['user-id'] = user.user_id;

    var token = localStorage.getItem("token");

    console.log('url==>', base + url);
    console.log('headers==>', headers);
    return await fetch(base + url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.text())
      .then((result) => {
        if (isJSONValid(result)) {
          return JSON.parse(result);
        } else {
          return messages.defaultError;
        }
      })
      .catch((error) => {
        console.log(error);
        return messages.defaultError;
      });
  } catch (error) {
    console.log(error);
    return messages.defaultError;
  }
}

/**
 * 
 * @param {*} url 
 * @returns 
 */
export async function GetData(url) {
  let user = await getUserData();
  try {
    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    console.log(base + url);
    return await fetch(base + url, requestOptions)
      .then(response => {
        return new Promise((resolve) => response.json()
          .then((json) => resolve({
            code: response.status,
            message: json,
          })));
      })
      .then(result => {
        // console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    console.log(error);
    return messages.defaultError;
  }
}

/**
 Delete
 **/
export async function DeleteData(url) {
  let user = await getUserData();
  try {
    var token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    console.log(base + url);
    return await fetch(base + url, requestOptions)
      .then(response => response.status)
      .then(result => {
        console.log(result);
        return result;
      })
      .catch(error => {
        return messages.defaultError;
      });
  } catch (error) {
    console.log(error);
    return messages.defaultError;
  }
}