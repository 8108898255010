import React, { Component } from "react";
import moment from 'moment';
import { DeleteData, formatErrors, GetData, getUserData, PatchData, PostData, urls } from '../common';
import { timers } from "jquery";
import Spinner from './Spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class EditForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: null,
            label: "",
            alertType: "alert-success",
            errorMsg: "",
            endpoint: "",
            fileUpload: null,
            otherFile: {},
            previewimg: [],
            previewimages: [],
            extraForms: [],
            images: false,
            features: false,
            featuresList: [],
            featureInput: "",
            isLoading: false,
            invoices: [],
            payload_data: {},
            flow_images: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ isLoading: true });
        this.setState({ columns: this.props.data.columns });
        this.setState({ label: this.props.data.label });
        this.setState({ endpoint: this.props.data.endpoint });
        this.setState({ extraForms: this.props.data.extraForms });

        var payload = {};
        for (var c in this.props.columns) {
            payload[this.props.columns[c].name] = "";
        }
        this.setState({ payload: payload });

        // //Get data
        var data = await GetData(this.props.data.endpoint + this.props.id + "/");
        this.setState({ payload_data: data.message });

        var payload = {};
        var previewimg = [];
        for (var c in data.message) {
            if (["image", "thumbs"].includes(c)) {

                if (data.message[c] !== "" || data.message[c] != null)
                    previewimg.push(data.message[c]);
            } else if (c == "images") {
                if (data.message[c] != null) {
                    if (this.props.data.endpoint == "flows/")
                        this.setState({ flow_images: data.message[c] });
                    else
                        this.setState({ previewimages: data.message[c] });
                }
                this.setState({ images: true });
            } else if (c == "features") {
                if (data.message[c] !== "" || data.message[c] != null) {
                    var features_ = data.message[c];
                    if (features_.length > 0) {
                        features_ = features_.split("|");
                        this.setState({ featuresList: features_ });
                    }
                }
                this.setState({ features: true });
            } else if (c == "category" && this.props.data.endpoint == "products/") {
                payload["category_id"] = `${data.message['subcategory']}|${data.message['category']}|${data.message['menu']}`;
            }
            else
                payload[c] = data.message[c];
            console.log(c, "=", data.message[c]);
        }

        if (previewimg.length > 0) {
            this.setState({ previewimg: previewimg });
        }

        /**
         * Table Product && get Measurement header
         */
        if (this.state.endpoint == "products/") {
            var productmeasurementassignment = await GetData("productmeasurementassignment/?product_id=" + this.props.id);
            console.log("productmeasurementassignment::", productmeasurementassignment);
            if (productmeasurementassignment.message.results.length > 0) {
                payload["measurement_id"] = productmeasurementassignment.message.results[0].measurement_id;
            }
        }

        this.setState({ payload: payload });

        //Order invoices
        if (this.props.data.endpoint == "orders/") {
            var invoices = await GetData("invoices/?order_id=" + this.props.id);
            if (invoices.code == 200) {
                this.setState({ invoices: invoices.message.results });
            }

        }
        this.setState({ isLoading: false });
    }

    onChange = async (event) => {
        var payload = this.state.payload;
        if (event.target.name == "category" && this.state.endpoint == "products/") {
            payload["category_id"] = event.target.value;
        } else
            payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });
    }

    onFileChange = async (event) => {
        var files = (this.state.fileUpload == null) ? [] : this.state.fileUpload;

        for (var f in event.target.files) {
            if (f != "item" && f != "length") {
                files.push(event.target.files[f]);
            }
        }
        this.setState({ fileUpload: files });
    }

    onOtherFileChange = async (event, name) => {
        var filesLabels = this.state.otherFile;
        var files = [];
        for (var f in event.target.files) {
            if (f != "item" && f != "length")
                files.push(event.target.files[f]);
        }
        filesLabels[name] = files;
        console.log("filesLabels::",name);
        this.setState({ otherFile: filesLabels });
    }

    SubmitForm = async () => {
        var valid = true;

        var errorLabels = "";
        for (var f in this.state.columns) {
            const data = this.state.columns[f];
            if (data.required == true) {
                if (this.state.payload[data.name] == undefined || this.state.payload[data.name] == "") {
                    valid = false;
                    errorLabels += data.name + ", ";
                }
            }
        }

        if (valid == false) {
            this.setState({ alertType: "alert-danger", errorMsg: "Kindly fill the following fields " + errorLabels.slice(0, -2) })
        } else {
            this.setState({ isLoading: true });
            var formData = new URLSearchParams();
            var user = await getUserData();

            if (this.state.fileUpload !== null || Object.keys(this.state.otherFile).length > 0) {
                formData = new FormData();
                if (this.state.images == true) {
                    var epoint = this.props.data.endpoint;

                    for (var img in this.state.fileUpload) {
                        var newformData = new FormData();
                        if (this.props.data.endpoint == "flows/") {
                            epoint = "flowsimage/";
                            newformData.append("flow", this.props.id);
                        } else if (this.props.data.endpoint == "products/") {
                            epoint = "productsimage/";
                            newformData.append("product", this.props.id);
                        }

                        newformData.append("image", this.state.fileUpload[img], this.state.fileUpload[img].name);
                        await PostData(epoint, newformData, true, "file");
                    }
                } else {
                    
                    for (var img in this.state.fileUpload) {
                        var indexx = Number(img) + 1;
                        var key_ = (this.props.data.endpoint == "fabrics/") ? "image" : "image";
                        if (["users/"].includes(this.props.data.endpoint)) key_ = "profile_picture";
                        if (["flows/"].includes(this.props.data.endpoint)) key_ = "images_" + indexx;
                        if (["products/"].includes(this.props.data.endpoint)) key_ = "images";
                        formData.append(key_, this.state.fileUpload[img], this.state.fileUpload[img].name);
                    }

                    console.log("otherFile::",this.state.otherFile);
                    for (var img in this.state.otherFile) {
                        for (var i in this.state.otherFile[img]) {
                            const list = this.state.otherFile[img][i];
                            formData.append(img, list, list.name);
                        }
                    }
                }
            }

            for (var p in this.state.payload) {
                if (["modified_user_id", "user_id", 'created_user', 'modified_user'].includes(p)) {
                    formData.append(p, user.id);
                }else if (p == "color") {
                    if (this.state.payload[p] === "null" || this.state.payload[p] === null) { } else
                        formData.append(p, this.state.payload[p]);
                } else
                    formData.append(p, this.state.payload[p]);
            }

            if (this.state.features == true) {
                formData.append("features", this.state.featuresList.join("|"));
            }

            //Product menu , category, sub category
            if (this.state.endpoint == "products/") {
                var menus = this.state.payload["category_id"].split("|");
                formData.append("menu", menus[2]);
                formData.append("category", menus[1]);
                formData.append("subcategory", menus[0]);
            }

            var createData = null;
            console.log("fileUpload::", this.state.fileUpload);
            console.log("formData::::", formData);

            if (this.state.fileUpload == null)
                createData = await PatchData(this.state.endpoint + `${this.props.id}/`, formData, true);
            else
                createData = await PatchData(this.state.endpoint + `${this.props.id}/`, formData, true, "file");


            // saving the measurement header if its a product update
            if (this.state.payload.measurement_id !== undefined) {
                if (this.state.payload.measurement_id !== "") {
                    if (this.state.endpoint == "products/") {
                        if (this.state.payload.measurement_id != "") {
                            var formDataMeasure = new URLSearchParams();

                            formDataMeasure.append("product_id", this.props.id);
                            formDataMeasure.append("measurement_id", this.state.payload.measurement_id);
                            var getMeasure = await GetData("productmeasurementassignment/?product_id=" + this.props.id + "&measurement_id=" + this.state.payload.measurement_id);
                            if (getMeasure.message.count == 0) {
                                await PostData("productmeasurementassignment/", formDataMeasure, true);
                            } else {
                                await PatchData("productmeasurementassignment/" + getMeasure.message.results[0].id + "/", formDataMeasure, true);
                            }
                        }
                    }
                }
            }

            console.log("createData::", createData);
            if (createData == null) {
                this.setState({ errorMsg: "Sorry unable to process your request at this time." });
                this.setState({ alertType: "alert-danger" });
            } else {
                if (createData.code == 200) {
                    this.setState({ fileUpload: null, errorMsg: this.state.label + " updated successfully", alertType: "alert-success" });

                } else {
                    var errors = formatErrors(createData.message);
                    this.setState({ errorMsg: errors });
                    this.setState({ alertType: "alert-danger" });
                }
            }
            this.setState({ isLoading: false });
            if (["content/", "blog/"].includes(this.state.endpoint))
                this.loadData();
        }
    }

    clearForm = async () => {
        var payload = this.state.payload;
        for (var p in payload) {
            payload[p] = "";
        }
        console.log(payload);
        this.setState({ payload: payload });
    }

    updateDelete = async (columnIndex, index, endpoint, data, value) => {
        console.log("columnIndex::", columnIndex, "index::", index, "endpoint::", endpoint, "data::", data, "value::", value);
        var params = "";

        var formData = new URLSearchParams();
        for (var d_ in data) {
            params += d_ + "=" + data[d_] + "&";
            formData.append(d_, data[d_]);
        }
        params = params.slice(0, -1);
        var checkData = await GetData(endpoint + "?" + params);

        var extraForm = this.state.extraForms;

        console.log("current::", extraForm[index].columns[columnIndex].values);
        console.log("checkData::", checkData.message.count);
        if (checkData.message.count == 0) {
            var insertData = await PostData(endpoint, formData, true);
            console.log("add", insertData.code, value);
            if (insertData.code == 201) {
                extraForm[index].columns[columnIndex].values.push(value);
            }
        } else {
            var insertData = await DeleteData(endpoint + checkData.message.results[0].id + "/");
            console.log("remove", insertData);
            if (insertData == 204) {
                let list = extraForm[index].columns[columnIndex].values;
                let newlist = [];
                for (var v in list) {
                    if (list[v] !== value) {
                        newlist.push(list[v]);
                    }
                }
                extraForm[index].columns[columnIndex].values = newlist;
            }
        }

        console.log("current::", extraForm[index].columns[columnIndex].values);
        this.setState({ extraForms: extraForm });
    }

    removeImageFromList = async (data) => {
        this.setState({ loading: true });
        var endpoint = "";
        if (this.props.data.endpoint == "flows/")
            endpoint = "flowsimage/";
        else if (this.props.data.endpoint == "products/")
            endpoint = "productsimage/";

        if (endpoint !== "") {
            var deleteImage = await DeleteData(endpoint + data.id + "/");
        }
        // this.loadData();
        window.location=window.location.href;
    }
    removeFlowImages = async (data) => {
        this.setState({ loading: true });
        var deleteImage = await DeleteData("flowsimage/" + data.id + "/");
        window.location=window.location.href;
    }

    saveBlob = async (data,name) => {
        var pl = this.state.payload;
        pl[name] = data;
        console.log("data::", data);
        // this.setState({ payload: pl });
    }

    render() {
        const { flow_images,payload_data, invoices, previewimages, columns, label, alertType, errorMsg, payload, previewimg, extraForms, fileUpload } = this.state;

        var formData = [];
        if (columns != null) {
            for (var f in columns) {
                const col=columns[f];
                const name_=columns[f].name;
                const formType=col.type;
                // console.log("blob",col);
                var width = 6;
                if (['features', 'created_user_id', 'modified_user_id', 'user_id', 'created_user', 'modified_user'].includes(name_)) { }
                else {
                    
                    var input = <input type={formType} className="form-control" name={col.name} placeholder={(col.placeholder == undefined) ? "" : col.placeholder}
                        onChange={(e) => this.onChange(e)} value={payload[col.name]} />;
                    if (formType == "select" || formType == "select-fixed") {
                        var optionList = [];
                        var extraParmas = "";
                        if (formType == "select-fixed")
                            extraParmas = "disabled";

                        for (var d in columns[f].data) {
                            optionList.push(<option className="dropdown-item" value={col.data[d].value}>{columns[f].data[d].label}</option>);
                        }
                        input = <select disabled={extraParmas} name={name_} tabindex="-1" className="select2 form-control" id="default-select"
                            onChange={(e) => this.onChange(e)} value={payload[name_]}>
                            <option className="dropdown-item" value="">Select {columns[f].label}</option>
                            {optionList}
                        </select>
                    } else if (formType == "file") {
                        if (name_ == "thumbs") {
                            input = <input type={formType} className="form-control" name="files[]"
                                onChange={(e) => this.onOtherFileChange(e, name_)} multiple />;
                        } else {
                            input = <input type={formType} className="form-control" name="files[]"
                                onChange={(e) => this.onFileChange(e)} multiple />;
                        }
                    } else if (formType == "blob") {
                        
                        width = 12;
                        input = <CKEditor
                            editor={ClassicEditor}
                            data={(payload[col.name] == null) ? "" : payload[name_]}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                let data = editor.getData();
                                this.saveBlob(data,col.name)
                                
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />;
                    } else if (formType == "fixed") {
                        input = <input type={formType} disabled readonly className="form-control" value={payload[name_]} />;
                    }
                    formData.push(<div className={`col-lg-${width} form-group`} style={{ marginBottom: 10 }}>
                        <label for="normal-field" className="form-control-label text-md-left">{columns[f].label}</label>
                        {input}
                    </div>);
                }
            }
        }

        let previewimgs = [];

        if (previewimg.length > 0) {
            for (var im in previewimg) {
                previewimgs.push(<img src={previewimg[im]} height="120" style={{ marginRight: 20, borderRadius: 20 }} />);
            }
        }
        if (previewimages.length > 0) {
            for (var im in previewimages) {
                const index = im;
                previewimgs.push(<div className="col-auto text-center">
                    <img src={previewimages[im].image} height="120" style={{ marginRight: 20, borderRadius: 20 }} />
                    <br /><a href="#" onClick={() => this.removeImageFromList(previewimages[index])}>Delete<i className="la la-trash"></i></a>
                </div>);
            }
        }

        /**
         * selected images
         */
        if (fileUpload !== null) {
            for (var im in fileUpload) {
                previewimgs.push(<img src={URL.createObjectURL(fileUpload[im])} height="120" style={{ marginRight: 20, borderRadius: 20, boxShadow: '0px 0px 10px #ccc' }} />);
            }
        }

        //extra forms 
        var extraFormss = [];

        for (var ef in extraForms) {
            const data = extraForms[ef];
            const index = ef;
            extraFormss.push(<div className="col-12"><br /><hr /><br /></div>);
            extraFormss.push(<div className="col-12">
                <h5><span className="fw-semi-bold">{data.label}</span></h5>
            </div>);

            var input = [];
            for (var d in data.columns) {
                var columnIndex = d;
                if (data.columns[d].type == "imagecheckbox") {
                    var values = data.columns[d].values;
                    for (var inner in data.columns[d].data) {
                        // console.log(inner, "values:::::", data.columns[d].data[inner]);
                        let fields = {};
                        const keyvalue = data.columns[d].data[inner].value;
                        if (data.columns[d].name == "productfabricassignment")
                            fields = { "status": 1, "product": this.props.id, "fabric": keyvalue };
                        else if (data.columns[d].name == "productillustrationsassignment")
                            fields = { "status": 1, "product_id": this.props.id, "illustrations_id": keyvalue };
                        else if (data.columns[d].name == "productconceptassignment")
                            fields = { "status": 1, "product": this.props.id, "concept": keyvalue };
                        else if (data.columns[d].name == "productflowassignment")
                            fields = { "status": 1, "product_id": this.props.id, "flow_id": keyvalue };

                        var img_source = data.columns[d].data[inner].image;
                        if (Array.isArray(data.columns[d].data[inner].image)) {
                            img_source = data.columns[d].data[inner].image[0].image;
                        }

                        extraFormss.push(<div className="col-auto">
                            <img src={img_source} height="120" style={{ borderRadius: 20 }} />
                            <div className="text-center">{data.columns[d].data[inner].label}</div>
                            <input type="checkbox" onClick={() => this.updateDelete(columnIndex, index, data.endpoint, fields, keyvalue)} checked={(values.includes(data.columns[d].data[inner].value)) ? true : false} style={{ position: 'absolute', top: 10, left: 30, width: 20, height: 20 }} />
                        </div>);
                    }
                }
            }
        }

        //Product features 
        var featureInput = [];
        if (this.state.features == true) {
            var fList = [];
            for (var f in this.state.featuresList) {
                fList.push(<div type="submit" className="btn btn-outline-dark mr-3 col-auto">
                    {this.state.featuresList[f]}
                    <a style={{ cursor: 'pointer' }} onClick={() => {
                        var fDelete = this.state.featuresList;
                        var newList = fDelete.filter(data => {
                            return data != this.state.featuresList[f];
                        });
                        this.setState({ featuresList: newList });
                    }}><i className="la la-trash"></i></a>
                </div>);
            }
            featureInput.push(<div className="col-lg-12 ml-10 mt-10 form-group row" style={{ marginBottom: 10 }}>
                {fList}
            </div>);
            featureInput.push(<div className="col-lg-6 form-group" style={{ marginBottom: 10 }}>
                <label for="normal-field" className="form-control-label text-md-left">{columns[8].label}<small>(Press enter to separate them)</small></label>
                <input type="text" className="form-control" name={columns[f].name} placeholder={(columns[8].placeholder == undefined) ? "" : columns[8].placeholder}
                    onChange={(e) => this.setState({ featureInput: e.target.value })} value={this.state.featureInput}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            var fl = this.state.featuresList;
                            fl.push(e.target.value);
                            this.setState({ featuresList: fl, featureInput: "" });
                        }
                    }} />
            </div>);
        }

        let invoiceList = [];
        for (var i in invoices) {
            var flows = [];
            var flowimages = invoices[i].flows.split("|");
            for (var inn in flowimages) {
                flows.push(<img src={flowimages[inn]} width="60" />);
            }
            invoiceList.push(<tr>
                <td>{invoices[i].id}</td>
                <td width={"10%"}>{invoices[i].quantity}</td>
                <td>{payload_data["currency_name"] + " " + invoices[i].price}</td>
                <td>{payload_data["currency_name"] + " " + invoices[i].delivery}</td>
                <td width={"10%"}><img src={invoices[i].fabric_image} width="60" /></td>
                <td>{flows}</td>
                <td>{invoices[i].measurement.split("|").map(data => {
                    var innerData = data.split(":");
                    return <div className="row">
                        <div className="col-5">{innerData[0]}</div>
                        <div className="col-1">:</div>
                        <div className="col-5">{innerData[1]}</div>
                    </div>
                })}</td>
            </tr>);
        }

        return (
            <>
                {(this.state.loading == true) && <Spinner bg="#fff" fill="#f00" />}
                {(errorMsg != "") &&
                    <div className="col-lg-8">
                        <div className={"alert " + alertType + " alert-sm alert-transparent"}>
                            {errorMsg}
                        </div>
                    </div>}

                {(previewimgs.length > 0) &&
                    <div className="col-12 row" style={{ marginBottom: 20 }}>
                        {previewimgs}
                    </div>}
                {formData}

                {featureInput}
                <br />
                {(this.state.isLoading == false) ?
                    <div className="form-actions bg-transparent col-12">
                        <button type="submit" className="btn btn-inverse mr-3" onClick={() => this.SubmitForm()}>Update {label}</button>
                        {/* <button type="button" className="btn btn-default">Cancel</button> */}
                    </div> : <div className="form-actions bg-transparent col-12"><center><Spinner bg="#fff" fill="#005792" /><br /><br /></center></div>}

                {extraFormss}


                {(invoices.length > 0) &&
                    <div className="col-12 row-fluid" style={{ marginBottom: 20, marginTop: 50 }}>
                        <h3>Order invoice</h3>
                        <table className="table table-striped" width="100%">
                            <thead>
                                <th>ID</th>
                                <th>QUANTITY</th>
                                <th>PRICE</th>
                                <th>DELIVERY</th>
                                <th>FABRIC</th>
                                <th>FLOWS</th>
                                <th>MEASUREMENTS</th>
                            </thead>
                            {invoiceList}
                        </table>
                    </div>}

                {(flow_images.length > 0) &&
                    <div className="col-12 row-fluid" style={{ marginBottom: 20, marginTop: 50 }}>
                        <h3>Images</h3>
                        <table className="table table-striped" width="100%">
                            <thead>
                                <th>ID</th>
                                <th>NAME</th>
                                <th>IMAGE</th>
                                <th colSpan={2} width="8%"></th>
                            </thead>
                            <tbody>
                                {flow_images.map(data => {
                                    return <tr>
                                        <td>{data.id}</td>
                                        <td>{data.name}</td>
                                        <td><img src={data.image} width="60"/></td>
                                        <td><a href={`/editflowimages?id=${data.id}`}><i className="la la-pencil"></i></a></td>
                                        <td><a onClick={()=>this.removeFlowImages(data)}><i className="la la-trash"></i></a></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
            </>
        )
    }
}
